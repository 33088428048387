// import React from 'react'
// import PropTypes from 'prop-types'

function AdminMessages() {
  return (
    <div>AdminMessages</div>
  )
}

AdminMessages.propTypes = {}

export default AdminMessages
